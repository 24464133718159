<template>
  <v-container>
    <v-row v-if="loading">
      <v-col>
        <h3 class="text-center">
          <v-progress-circular indeterminate class="ml-3" color="primary" />
          Getting data . . .
        </h3>
      </v-col>
    </v-row>

    <v-alert
      v-show="email_sent"
      transition="fade-transition"
      color="success"
      type="success"
      style="z-index: 2; position: fixed; right: 15px; min-width: 300px"
    >
      Email sent!
    </v-alert>

    <v-alert
      v-show="error"
      transition="fade-transition"
      color="error"
      type="error"
      style="z-index: 2; position: fixed; right: 15px; min-width: 300px"
    >
      {{ errorMessage }}
    </v-alert>

    <v-row v-if="!loading" justify="center">
      <v-col xs="12" xl="6">
        <v-row>
          <v-col>
            <v-card>
              <v-card-title> Create Manual Entry </v-card-title>

              <v-card-text>
                By clicking the get started button below, you will be directed
                to a KYC home page where you are required to complete all
                different types of KYC form and fill in the personal or
                corporate information and any other relevant data.
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="handle_get_started_btn" text>
                  Get Started
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card>
              <v-card-title> Send Email </v-card-title>

              <v-card-text>
                The client can receive an access token via email through this
                option.
              </v-card-text>

              <v-card-text>
                Select Users:

                <div v-for="user in company_users" :key="user.id">
                  <v-checkbox
                    v-model="selected_users"
                    :label="user.last_name + ', ' + user.first_name"
                    :value="user.id"
                    hide-details
                  ></v-checkbox>
                </div>
              </v-card-text>

              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  @click="createFormAccessLink"
                  :disabled="selected_users.length === 0"
                  text
                >
                  Send
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn
              @click="
                $router.push(`/dashboard/admin/company/read/${company_id}`)
              "
            >
              back
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from "@/plugins/API";

export default {
  name: "CreateKYC",

  props: ["company_id"],

  data: () => ({
    loading: false,
    email_sent: false,
    error: false,
    errorMessage: "",
    selected_users: [],
    company_users: [],
  }),

  methods: {
    handle_get_started_btn() {
      console.log("Get Started button clicked");

      this.$router.push({
        path: `/dashboard/admin/company/${this.company_id}/kyc/homepage`,
      });
    },

    async createFormAccessLink() {
      try {
        const res = await API().post(
          "api/form/create_investor_kyc_access_link",
          {
            company_id: this.company_id,
            users: this.selected_users,
          }
        );

        if (res.status === 201) {
          console.log("Form access link was created. ", res.data);

          this.email_sent = true;

          setTimeout(() => {
            this.email_sent = false;
          }, 3000);
        } else {
          console.log("Form access link not created.");
        }
      } catch (error) {
        console.log(error);
      }
    },

    async readCompanyData() {
      try {
        const companyData = await API().get(
          `/api/internal-admin/company/${Number(this.company_id)}`
        );

        if (companyData.status == 200) {
          this.company_users = companyData.data.users;
          // console.log("Company Users: ", this.company_users);
        }
      } catch (error) {
        this.error = true;
        this.errorMessage = "Getting Company Data " + error.message;
        console.log("Getting Company Data " + error.message);

        setTimeout(() => {
          this.error = false;
          this.errorMessage = "";
        }, 3000);
      }
    },
  },

  mounted() {
    this.readCompanyData();
  },
};
</script>
